<template>
    <div class="details-box">
        <div class="container">
            <h3 class="title-info">
                <img src="../../../assets/code-icon.png" alt="">
                <span>证书基本信息</span>
            </h3>
            <div class="certificate-box">
                <img src="../../../assets/certificate-bj.png" alt="">
                <div class="certificate-details">
                    <div v-if="detailsList.certType">
                        <span>认证类型：</span>
                        <span v-show="detailsList.certType == 1">资质认证</span>
                        <span v-show="detailsList.certType == 2">信用认证</span>
                        <span v-show="detailsList.certType == 3">管理体系认证</span>
                        <span v-show="detailsList.certType == 4">服务认证</span>
                        <span v-show="detailsList.certType == 5">强制性产品认证</span>
                        <span v-show="detailsList.certType == 6">自愿性产品认证</span>
                        <span v-show="detailsList.certType == 7">食品农产品认证</span>
                    </div>
                    <div v-if="detailsList.certProject">
                        <span>认证项目：</span>
                        <span>{{detailsList.certProject}}</span>
                    </div>
                    <div>
                        <div v-if="detailsList.certNo">
                            <span>证书编号：</span>
                            <span>{{detailsList.certNo}}</span>
                        </div>
                        <div v-if="detailsList.status">
                            <span class="certificate-status">证书状态：</span>
                            <span v-show="detailsList.status==1" class="status effective">有效</span>
                            <span v-show="detailsList.status==2" class="status suspended">暂停</span>
                            <span v-show="detailsList.status==3" class="status undo">撤销</span>
                            <span v-show="detailsList.status==4" class="status cancellation">注销</span>
                            <span v-show="detailsList.status==5" class="status overdue">过期</span>
                        </div>
                    </div>
                    <div v-if="detailsList.certBody">
                        <span>认证对象：</span>
                        <span>{{detailsList.certBody}}</span>
                    </div>
                    <div v-if="detailsList.rating" class="scope">
                        <span>评定等级：</span>
                        <div v-html="changeLevel(detailsList.rating)">{{detailsList.rating}}</div>
                    </div>
                    <div v-if="detailsList.evaluationBasis">
                        <span>认定依据：</span>
                        <span>{{detailsList.evaluationBasis}}</span>
                    </div>
                    <div v-if="detailsList.businessScope" class="scope">
                        <span>业务范围：</span>
                        <div>{{detailsList.businessScope}}</div>
                    </div>
                    <div>
                        <div v-if="detailsList.issueDate">
                            <span>颁证日期：</span>
                            <span>{{detailsList.issueDate}}</span>
                        </div>
                        <div v-if="detailsList.validateDate">
                            <span>到期日期：</span>
                            <span>{{detailsList.validateDate}}</span>
                        </div>
                    </div>
                    <div v-if="detailsList.issueOrgan"> 
                        <span>颁证机构：</span>
                        <span @click="goInstitutions(detailsList.issueOrgan)" class="awardingBodies">{{detailsList.issueOrgan}}</span>
                    </div>
                </div>
            </div>
            <h3 class="title-info">
                <img src="../../../assets/query-icon.png" alt="">
                <span>获证单位信息</span>
            </h3>
            <div class="detailed-info">
                <img src="../../../assets/bj-pic.png" alt="">
                <div>
                    <div v-if="detailsList.enterpriseName">
                        <span>单位全称：</span>
                        <span>{{detailsList.enterpriseName}}</span>
                    </div>
                    <div v-if="detailsList.enterpriseEnglishName">
                        <span>英文名称：</span>
                        <span>{{detailsList.enterpriseEnglishName}}</span>
                    </div>
                    <div v-if="detailsList.socialCreditNo">
                        <span>统一社会信用代码：</span>
                        <span>{{detailsList.socialCreditNo}}</span>
                    </div>
                    <div v-if="detailsList.regAuthority">
                        <span>登记机关：</span>
                        <span>{{detailsList.regAuthority}}</span>
                    </div>
                    <div v-if="detailsList.regCapital">
                        <span>注册资金：</span>
                        <span>{{detailsList.regCapital}}万元</span>
                    </div>
                    <div v-if="detailsList.regAddress">
                        <span>注册地址：</span>
                        <span>{{detailsList.regAddress}}</span>
                    </div>
                    <div v-if="detailsList.operatePeriod">
                        <span>营业期限：</span>
                        <span>{{detailsList.operatePeriod}}</span>
                    </div>
                    <div v-if="detailsList.webSite">
                        <span>单位网址：</span>
                        <span @click="goPage(detailsList.webSite)" class="website-style">{{detailsList.webSite}}</span>
                    </div>
                    <div v-if="detailsList.enterpriseScope" class="scope">
                        <span>经营范围：</span>
                        <div>{{detailsList.enterpriseScope}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      detailsList: {}
    };
  },
  methods: {
    goPage (url) {
        window.open('//'+url)
    },
    goInstitutions(valName){
        localStorage.setItem('valName',valName)
        this.$router.push('/professionalInstitutions');
        // this.$router.push({ path: '/professionalInstitutions', query: { valName: valName } })
    },
    getdata() {
      axios({
        method: "get",
        url:
            process.env.VUE_APP_BASE_URL +
            "/rzgs/certdetail?id=" + this.$route.query.id+"&certType="+ this.$route.query.certType
      }).then((response) => {
        if (response.data.status === 0) {
            this.detailsList = response.data.data
        }
      });
    },
    changeLevel: function(content){
        if(content){
            content = content.replace(/;|\$/g, '</br>')
            return content
        }
    }
  },
  mounted() {
    this.getdata();
  }
};
</script>

<style lang="scss" scoped>
.details-box{
    width: 100%;
    .container {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        .title-info{
            margin:40px 0px 24px 0px;
        }
        .title-info>img{
            width: 28px;
            height: 28px;
            background-size: 100% 100%;
            vertical-align: middle;
            margin-right:5px;
        }
        .title-info>span{
            font-size: 24px;
            font-weight: 500;
            color: #323233;
            vertical-align: middle;
        }
        .certificate-box{
            position: relative;
            width: 100%;
            height: 796px;
            background-color: #fff;
        }
        .certificate-box>img{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
        }
        .certificate-box .certificate-details{
            position: relative;
            width: 900px;
            margin-left:150px;
            padding-top:192px;
        }
        .certificate-box .certificate-details>div{
            margin-bottom: 32px;
        }
        .certificate-box .certificate-details>div>span:nth-child(1){
            height: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #969799;
        }
        .certificate-box .certificate-details>div>span:nth-child(2){
            font-size: 16px;
            font-weight: 400;
            color: #323233;
        }
        .awardingBodies{
            cursor: pointer;
        }
        .certificate-details>div{
            overflow: hidden;
        }
        .certificate-details>div>div{
            float:left;
            width: 44%;
        }
        .certificate-details>div>div>span:nth-child(1){
            height: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #969799;
        }
        .certificate-details>div>div>span:nth-child(2){
            font-size: 16px;
            font-weight: 400;
            color: #323233;
        }
        .certificate-details .scope{
            overflow: hidden;
        }
        .certificate-details .scope>span{
            float:left;
        }
        .certificate-details .scope>div{
            float:left;
            width: 816px;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #323233 !important;
        }
        .certificate-details .certificate-status{
            vertical-align: middle;
        }
        .certificate-details .status{
            position: relative;
            padding:2px 5px;
            text-align: center;
            border-radius: 2px;
            font-size: 12px !important;
            color:#fff !important;
            vertical-align: middle;
        }
        .effective{
            background: #00B01B;
        }
        .suspended{
            background: #BFCA00;
        }
        .undo{
            background: #B00004;
        }
        .cancellation{
            background: #FF0000;
        }
        .overdue{
            background: #808080;
        }
        .detailed-info{
            position: relative;
            width: 100%;
            min-height: 402px;
            margin-bottom: 96px;
            background-color: #fff;
        }
        .detailed-info>img{
            position: absolute;
            right:0;
            bottom:0;
            width: 402px;
            height: 402px;
            background-size: 100% 100%;
            z-index: 10;
        }
        .detailed-info>div{
            position: relative;
            padding:32px 0px 0px 32px;
            z-index: 1000;
        }
        .detailed-info>div>div{
            padding-bottom: 32px;
        }
        .detailed-info>div>div>span:nth-child(1),
        .detailed-info>div>.scope>span{
            font-size: 16px;
            font-weight: 400;
            color: #969799;
        }
        .detailed-info>div>div>span:nth-child(2){
            font-size: 16px;
            font-weight: 400;
            color: #323233;
        }
        .detailed-info>div>.scope{
            overflow: hidden;
        }
        .detailed-info>div>.scope>span{
            float:left;
        }
        .detailed-info>div>.scope>div{
            float:left;
            width: 1049px;
            line-height: 26px;
        }
        .detailed-info .website-style{
            color:#10439B !important;
            cursor: pointer;
        }
    }
}
</style>